import { Playground, Props } from "docz";
import Banner from "../../../../src/components/banner/banner.jsx";
import Container from "../../../../src/components/container/container.jsx";
import * as React from 'react';
export default {
  Playground,
  Props,
  Banner,
  Container,
  React
};