import { Playground, Props } from "docz";
import Video from "../../../../src/components/video/video.js";
import MyVideo from "../../../../src/videos/Clothes4All-Beach-EN_original.mp4";
import PRODUCT_VIDEOS from "../../../../src/data/products/product-videos.js";
import * as React from 'react';
export default {
  Playground,
  Props,
  Video,
  MyVideo,
  PRODUCT_VIDEOS,
  React
};