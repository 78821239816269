// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-index-mdx": () => import("./../../../docs/index.mdx" /* webpackChunkName: "component---docs-index-mdx" */),
  "component---docs-rules-mdx": () => import("./../../../docs/rules.mdx" /* webpackChunkName: "component---docs-rules-mdx" */),
  "component---readme-md": () => import("./../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-components-banner-banner-mdx": () => import("./../../../src/components/banner/banner.mdx" /* webpackChunkName: "component---src-components-banner-banner-mdx" */),
  "component---src-components-button-button-mdx": () => import("./../../../src/components/button/button.mdx" /* webpackChunkName: "component---src-components-button-button-mdx" */),
  "component---src-components-video-video-mdx": () => import("./../../../src/components/video/video.mdx" /* webpackChunkName: "component---src-components-video-video-mdx" */),
  "component---src-page-templates-product-list-by-gender-page-js": () => import("./../../../src/page-templates/product-list-by-gender-page.js" /* webpackChunkName: "component---src-page-templates-product-list-by-gender-page-js" */),
  "component---src-page-templates-product-list-by-language-page-js": () => import("./../../../src/page-templates/product-list-by-language-page.js" /* webpackChunkName: "component---src-page-templates-product-list-by-language-page-js" */),
  "component---src-page-templates-product-list-by-type-page-js": () => import("./../../../src/page-templates/product-list-by-type-page.js" /* webpackChunkName: "component---src-page-templates-product-list-by-type-page-js" */),
  "component---src-page-templates-product-page-js": () => import("./../../../src/page-templates/product-page.js" /* webpackChunkName: "component---src-page-templates-product-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-bruksomrader-no-js": () => import("./../../../src/pages/bruksomrader.no.js" /* webpackChunkName: "component---src-pages-bruksomrader-no-js" */),
  "component---src-pages-butikk-betingelser-no-js": () => import("./../../../src/pages/butikk/betingelser.no.js" /* webpackChunkName: "component---src-pages-butikk-betingelser-no-js" */),
  "component---src-pages-butikk-kampanje-no-js": () => import("./../../../src/pages/butikk/kampanje.no.js" /* webpackChunkName: "component---src-pages-butikk-kampanje-no-js" */),
  "component---src-pages-butikk-kassen-betaling-no-js": () => import("./../../../src/pages/butikk/kassen/betaling.no.js" /* webpackChunkName: "component---src-pages-butikk-kassen-betaling-no-js" */),
  "component---src-pages-butikk-kassen-handlekurv-no-js": () => import("./../../../src/pages/butikk/kassen/handlekurv.no.js" /* webpackChunkName: "component---src-pages-butikk-kassen-handlekurv-no-js" */),
  "component---src-pages-butikk-kassen-kvittering-no-js": () => import("./../../../src/pages/butikk/kassen/kvittering.no.js" /* webpackChunkName: "component---src-pages-butikk-kassen-kvittering-no-js" */),
  "component---src-pages-butikk-kassen-personlige-opplysninger-no-js": () => import("./../../../src/pages/butikk/kassen/personlige-opplysninger.no.js" /* webpackChunkName: "component---src-pages-butikk-kassen-personlige-opplysninger-no-js" */),
  "component---src-pages-butikk-levering-no-js": () => import("./../../../src/pages/butikk/levering.no.js" /* webpackChunkName: "component---src-pages-butikk-levering-no-js" */),
  "component---src-pages-butikk-retur-no-js": () => import("./../../../src/pages/butikk/retur.no.js" /* webpackChunkName: "component---src-pages-butikk-retur-no-js" */),
  "component---src-pages-credits-en-js": () => import("./../../../src/pages/credits.en.js" /* webpackChunkName: "component---src-pages-credits-en-js" */),
  "component---src-pages-how-to-en-js": () => import("./../../../src/pages/how-to.en.js" /* webpackChunkName: "component---src-pages-how-to-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-no-js": () => import("./../../../src/pages/index.no.js" /* webpackChunkName: "component---src-pages-index-no-js" */),
  "component---src-pages-om-oss-no-js": () => import("./../../../src/pages/om-oss.no.js" /* webpackChunkName: "component---src-pages-om-oss-no-js" */),
  "component---src-pages-opphavsrett-no-js": () => import("./../../../src/pages/opphavsrett.no.js" /* webpackChunkName: "component---src-pages-opphavsrett-no-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-shop-campaign-en-js": () => import("./../../../src/pages/shop/campaign.en.js" /* webpackChunkName: "component---src-pages-shop-campaign-en-js" */),
  "component---src-pages-shop-checkout-cart-en-js": () => import("./../../../src/pages/shop/checkout/cart.en.js" /* webpackChunkName: "component---src-pages-shop-checkout-cart-en-js" */),
  "component---src-pages-shop-checkout-payment-details-en-js": () => import("./../../../src/pages/shop/checkout/payment-details.en.js" /* webpackChunkName: "component---src-pages-shop-checkout-payment-details-en-js" */),
  "component---src-pages-shop-checkout-personal-details-en-js": () => import("./../../../src/pages/shop/checkout/personal-details.en.js" /* webpackChunkName: "component---src-pages-shop-checkout-personal-details-en-js" */),
  "component---src-pages-shop-checkout-receipt-en-js": () => import("./../../../src/pages/shop/checkout/receipt.en.js" /* webpackChunkName: "component---src-pages-shop-checkout-receipt-en-js" */),
  "component---src-pages-shop-delivery-en-js": () => import("./../../../src/pages/shop/delivery.en.js" /* webpackChunkName: "component---src-pages-shop-delivery-en-js" */),
  "component---src-pages-shop-returns-en-js": () => import("./../../../src/pages/shop/returns.en.js" /* webpackChunkName: "component---src-pages-shop-returns-en-js" */),
  "component---src-pages-shop-terms-en-js": () => import("./../../../src/pages/shop/terms.en.js" /* webpackChunkName: "component---src-pages-shop-terms-en-js" */),
  "component---src-pages-v-1-js": () => import("./../../../src/pages/v1.js" /* webpackChunkName: "component---src-pages-v-1-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */)
}

